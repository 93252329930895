import React from 'react';
import { CURRICULUM_WORK } from './data/curriculum';

const curriculum = CURRICULUM_WORK.map((item, idx) => (
  <div className={`tile ${item.classTile}`} key={`curr-${idx}`}>
    <div className="text">
      <h2>{item.title}</h2>
      <h3 className="animate-text">{item.position}</h3>
      <p
        className="animate-text"
        dangerouslySetInnerHTML={{ __html: item.abstract }}
      />
    </div>
    <div className="img" style={item.image} />
  </div>
));

const CurriculumCard = () => curriculum;

export default CurriculumCard;
