import React from 'react';
import { SPECIALIZATIONS } from './data/specializations';

import './styles/curriculumSpecializations.scss';

const specialization = SPECIALIZATIONS.map((item, idx) => (
  <li className={`chart__bar ${item.classTile}`} style={{ width: `${item.percentual}%` }} key={`curr-${idx}`} title={item.title}>
    <span className="chart__label">
      {item.component} {item.name}
      <span>{`${item.percentual}%`}</span>
    </span>
  </li>
));

export const  CurriculumSpecializations = () => (
    <div className="charts">
      <div className="chart chart--dev">
        <span className="chart__title">Development</span>
        <ul className="chart--horiz">
          { specialization }
        </ul>
      </div>
    </div>
);