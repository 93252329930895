import madisoftLogo from '../../images/loghi/madisoft-logo.jpg';
import maestraleLogo from '../../images/loghi/maestrale-logo.jpg';
import accentureLogo from '../../images/loghi/accenture-logo.jpg';
import immobiliareLogo from '../../images/loghi/immobiliare-logo.png';
import immobiliareLogoBig from '../../images/loghi/immobiliare-big-logo.jpg';
import meditrialLogo from '../../images/loghi/meditrial-logo.png';
import operagraficaLogo from '../../images/loghi/operagrafica-logo.jpeg';
import spazio360Logo from '../../images/loghi/spazio360-logo.jpg';

export const CURRICULUM_WORK = [
  {
    title: 'Immobiliare.it',
    position: 'Senior Full Stack Devs in Software Development Team',
    abstract:
      'Return to 🏠 Immobiliare.it @ImmobiliareLabs in the Software Development Team, with a focus on developing and maintaining web applications through the use of server-side and client-side technologies | More in <a href="https://www.linkedin.com/in/stefanofrasca/" target="_blank">Linkedin</a>.',
    image: {
      backgroundImage: `url(${immobiliareLogoBig})`,
    },
    classTile: ' big text__white',
  },
  {
    title: 'Maestrale IT',
    position: 'Fullstack Devs & Frontend Specialist',
    abstract:
      'Bring Frontend experience to enhance internal FE framework - Now in Angular/JavaSpringBoot Team @Turin | More in <a href="https://www.linkedin.com/in/stefanofrasca/" target="_blank">Linkedin</a>.',
    image: {
      backgroundImage: `url(${maestraleLogo})`,
    },
    classTile: '',
  },
  {
    title: 'Madisoft',
    position: 'Frontend Developer',
    abstract:
      'Optimize the frontend structure of the main products "Nuvola" through Webpack, semantic html clean and WebComponents. Made Docker env for Cordova/Ionic application and init demo application in React Native | More in <a href="https://www.linkedin.com/in/stefanofrasca/" target="_blank">Linkedin</a>.',
    image: {
      backgroundImage: `url(${madisoftLogo})`,
    },
    classTile: '',
  },
  {
    title: 'Meditrial Europe',
    position: 'Fullstack & Frontend Devs',
    abstract:
      'Working on a ZF/Dojo based clinical trial software. I have tried to provide a more modern, client-side structuring (especially JS), through the Dojo\'s framework used in company, and resolution of some bugs in old browsers | More in <a href="https://www.linkedin.com/in/stefanofrasca/" target="_blank">Linkedin</a>.',
    image: {
      backgroundImage: `url(${meditrialLogo})`,
    },
    classTile: '',
  },
  {
    title: 'Immobiliare.it',
    position: 'Senior Fullstack Devs in Frontent Team',
    abstract:
      'I developed experience in @ImmobiliareLabs up to manage all the technological flows for private ads. I have worked with PHP / Symfony, Javascript ES6 and Bash for script management. | More in <a href="https://www.linkedin.com/in/stefanofrasca/" target="_blank">Linkedin</a>.',
    image: {
      backgroundImage: `url(${immobiliareLogo})`,
    },
    classTile: '',
  },
  {
    title: 'Accenture ATS',
    position: 'TIBCO/Java Programmers',
    abstract:
      'Programmer at ATS Italy on TIBCO Business Works. I followed a training period on the platform in TIBCO BW Alitalia SpA, have subsequently been assigned to the team "ESB/CRM - Wind SpA". | More in <a href="https://www.linkedin.com/in/stefanofrasca/" target="_blank">Linkedin</a>.',
    image: {
      backgroundImage: `url(${accentureLogo})`,
    },
    classTile: '',
  },
  {
    title: 'Evolving/Operagrafica',
    position: 'Web Engineer',
    abstract:
      'AMP platform programmer specializing in Ajax, Javascript and Flash Framework Environment (include Actionscript OOP). | More in <a href="https://www.linkedin.com/in/stefanofrasca/" target="_blank">Linkedin</a>.',
    image: {
      backgroundImage: `url(${operagraficaLogo})`,
    },
    classTile: '',
  },
  {
    title: 'Spazio360',
    position: 'Web Developer',
    abstract:
      'Web devs on some dynamic site in Flash/AS3 and XML as through pass data. I conceived and managed my thesis "Enterprise Mashup in Web 2.0 Era" | More in <a href="https://www.linkedin.com/in/stefanofrasca/" target="_blank">Linkedin</a>.',
    image: {
      backgroundImage: `url(${spazio360Logo})`,
    },
    classTile: '',
  },
];
