import React from 'react';
import PropTypes from 'prop-types';

import madisoftLogo from '../images/loghi/madisoft-logo.jpg';
import maestraleLogo from '../images/loghi/maestrale-logo.jpg';
import './styles/madefor.scss';

export const MadeFor = ({ agency }) => {
  if (agency === 'madisoft') {
    return (
      <>
        <div>
          <div className="img-madefor">
            write for
            <a
              href="https://labs.madisoft.it/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={madisoftLogo} alt="Madisoft Labs" />
            </a>
          </div>
        </div>
      </>
    );
  }
  if (agency === 'maestrale') {
    return (
      <>
        <div>
          <div className="img-madefor">
            write for
            <a
              href="http://www.maestrale.it/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={maestraleLogo} className="big" alt="Maestrale IT" />
            </a>
          </div>
        </div>
      </>
    );
  }
  return <></>;
};

MadeFor.propTypes = {
  for: PropTypes.string,
};


