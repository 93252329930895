import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faJs, faReact, faAngular, faPhp, faSymfony, faHtml5, faCss3Alt, faSass, faLess, faJava } from '@fortawesome/free-brands-svg-icons'
import { faDatabase } from '@fortawesome/free-solid-svg-icons'

export const SPECIALIZATIONS = [
  {
    name: 'JS',
    title: 'Javascript',
    group: 'JS',
    component: <FontAwesomeIcon icon={faJs} />,
    classTile: '',
    percentual: 95
  },
  {
    name: 'VanillaJS',
    title: 'Plain-VanillaJS (ES6 tech specification)',
    group: 'JS',
    component: <FontAwesomeIcon icon={faJs} />,
    classTile: 'js',
    percentual: 100
  },
  {
    name: 'JQuery',
    title: 'JQuery',
    group: 'JS',
    component: <FontAwesomeIcon icon={faJs} />,
    classTile: 'js',
    percentual: 100
  },
  {
    name: 'React',
    title: 'React & React Native',
    group: 'JS',
    component: <FontAwesomeIcon icon={faReact} />,
    classTile: 'js',
    percentual: 85
  },
  {
    name: 'Angular',
    title: 'Angular Framework',
    group: 'JS',
    component: <FontAwesomeIcon icon={faAngular} />,
    classTile: 'js',
    percentual: 80
  },
  {
    name: 'HTML',
    title: 'HTML',
    group: 'frontend',
    component: <FontAwesomeIcon icon={faHtml5} />,
    classTile: 'frontend',
    percentual: 100
  },
  {
    name: 'CSS',
    title: 'CSS/CSS3',
    group: 'frontend',
    component: <FontAwesomeIcon icon={faCss3Alt} />,
    classTile: 'frontend',
    percentual: 100
  },
  {
    name: 'SASS',
    title: 'SASS',
    group: 'frontend',
    component: <FontAwesomeIcon icon={faSass} />,
    classTile: 'frontend',
    percentual: 85
  },
  {
    name: 'LESS',
    title: 'LESS',
    group: 'frontend',
    component: <FontAwesomeIcon icon={faLess} />,
    classTile: 'frontend',
    percentual: 65
  },
  {
    name: 'PHP',
    title: 'PHP',
    group: 'backend',
    component: <FontAwesomeIcon icon={faPhp} />,
    classTile: 'backend',
    percentual: 90
  },
  {
    name: 'Symfony',
    title: 'Symfony Framework',
    group: 'backend',
    component: <FontAwesomeIcon icon={faSymfony} />,
    classTile: 'backend',
    percentual: 65
  },
  {
    name: 'Java',
    title: 'Java & Java Spring',
    group: 'backend',
    component: <FontAwesomeIcon icon={faJava} />,
    classTile: 'backend',
    percentual: 70
  },
  {
    name: 'Mysql, PostgreSQL, MSSQL',
    title: 'Engine DB',
    group: 'backend',
    component: <FontAwesomeIcon icon={faDatabase} />,
    classTile: 'storage',
    percentual: 95
  }
];
